@font-face {
    font-family: "DBHeavent";
    src: url("../../assets/fonts/DBHeavent.ttf") format("truetype");
    // url("./assets/fonts/maledpan-regular-webfont.eot?#iefix") format("embedded-opentype"),
    // url("./assets/fonts/maledpan-regular-webfont.woff") format("woff"),
    // url("./assets/fonts/maledpan-regular-webfont.woff2") format("woff2"),
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: "DBHeavent";
    src: url("../../assets/fonts/DBHeaventMed.ttf") format("truetype");
    // url("./assets/fonts/maledpan-regular-webfont.eot?#iefix") format("embedded-opentype"),
    // url("./assets/fonts/maledpan-regular-webfont.woff") format("woff"),
    // url("./assets/fonts/maledpan-regular-webfont.woff2") format("woff2"),
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: "DBHeavent";
    src: url("../../assets/fonts/DBHeaventBd.ttf") format("truetype");
    // url("./assets/fonts/maledpan-regular-webfont.eot?#iefix") format("embedded-opentype"),
    // url("./assets/fonts/maledpan-regular-webfont.woff") format("woff"),
    // url("./assets/fonts/maledpan-regular-webfont.woff2") format("woff2"),
    font-weight: 700;
    font-style: normal;
}


body * {
    font-family: var(--default-font);
    color: var(--color-gray);
}

h1,
h2,
h3,
h4,
h5,
h4 {
    font-family: var(--default-font);
    font-weight: bold;
}

ion-badge {
    font-family: var(--default-font);
}

.tx-bold {
    font-family: var(--default-font) !important;
    font-weight: bold;
}
