body {
    font-size: 20px !important;
    font-family: var(--default-font);
    font-weight: 400;
    color: var(--color-gray);
}

h1 {
    font-size: 50px;
    margin-bottom: 20px;
    color: var(--color-brand);
    line-height: 52px;
}

h2 {
    font-size: 30px;
    margin-bottom: 0;
    color: var(--color-brand);
    line-height: 34px;
}

h3 {
    font-size: 24px;
}

h4 {
    font-size: 20px;
}

p {
    font-size: 20px;
    line-height: 22px;
    margin-top: 0;
    margin-bottom: 10px;
}

strong {
    // font-family: var(--default-font);
    font-weight: bold;
}

.title {
    font-size: 24px;
    line-height: 22px;
}

small {
    font-size: 18px;
    line-height: 20px;
}

/**
 * Text
 */

.text-center {
    text-align: center;
}

.text-primary {
    color: var(--primary);
}

.text-danger {
    color: red;
}

.tx-sm {
    font-size: 18px;
    line-height: 20px;
}

.tx-xs {
    font-size: 16px;
    line-height: 18px;
}

.tag {
    font-size: 16px;
    line-height: 18px;
    font-weight: bold;
}

.tx-12 {
    font-size: 12px !important;
}

.tx-13 {
    font-size: 13px !important;
}

.tx-14 {
    font-size: 14px !important;
}

.tx-15 {
    font-size: 15px !important;
}

.tx-16 {
    font-size: 16px !important;
}

.tx-17 {
    font-size: 17px !important;
}

.tx-18 {
    font-size: 18px !important;
}

/**
 * Margin & Padding
 */

.mt-5 {
    margin-top: 5px;
}

.mt-15 {
    margin-top: 15px;
}

.mt-30 {
    margin-top: 30px;
}

/**
 *
 */
