@mixin boxShadow($opacity: 0.2) {
    // --box-shadow: none !important;
    box-shadow: 0 4px 5px rgba(0, 0, 0, $opacity);
}

@mixin transition() {
    transition: all 0.25s ease-out;
}

@mixin cfcForm() {
    h1,
    h2 {
        color: var(--color-brand-dark);
    }

    h2 {
        margin: 30px 0;
    }

    .content {
        padding-bottom: 15px;
        margin-top: 0;
    }

    .form {
        margin-bottom: 0;
        .table {
            margin-top: 10px;
            // margin-bottom: 64px;
            tbody > tr > td {
                vertical-align: top;

                .img {
                    width: 60px;
                    height: 60px;

                    display: flex;
                    justify-content: center;
                    align-items: center;

                    background: #ffffff;
                    border-radius: 50%;
                    margin: 5px 20px 20px 0;
                }

                img {
                    border-radius: 50%;
                }

                h4 {
                    font-size: 24px;
                    line-height: 1;
                    margin-top: 8px;
                    color: #fff;
                    margin-bottom: 0;
                }

                p {
                    font-size: 20px;
                    color: var(--color-brand);
                }
            }
        }

        .form-control {
            font-size: 30px;
            font-weight: 500;
            text-align: center;
            width: 80px;
            height: 42px;
            padding: 4px 10px;
            margin-left: 10px;
            margin-top: 8px;
            background: #eff9ec !important;
            border: 1px solid #eff9ec !important;
            border-radius: 20px;
            color: var(--color-brand-dark);

            &.disabled {
                color: var(--color-brand-light) !important;
            }

            &[readonly] {
                pointer-events: unset;
            }
        }
    }

    .form-actions {
        position: relative;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 25px;
        margin: 20px 0;
        margin-bottom: 60px;

        .btn-default {
            background: transparent;
            display: flex;
            justify-content: center;
            align-items: center;
            border: 0;
            color: var(--color-gray);
        }

        .btn-primary {
            .inner {
                margin-top: -4px;
                margin-left: 1px;
            }
        }
    }
}

@mixin scroller() {
    .scroll-container {
        margin-top: 15px;
        margin-right: -24px;
        margin-left: -24px;
        .slider {
            display: flex;
            flex-wrap: nowrap;
            overflow-x: auto;
           
            &::-webkit-scrollbar {
                display: none;
            }
            .item {
                flex: 0 0 auto;
                width: 64px;
                height: 64px;
                background: #ffffff;
                border-radius: 50%;
                margin-right: 16px;
                display: flex;
                align-items: center;
                justify-content: center;
                overflow: hidden;
                &.active {
                    background: #ffffff;
                    // background: var(--color-brand);
                }
                &:first-of-type {
                    margin-left: 24px;
                }
                &:last-of-type {
                    margin-right: 0;
                    background: transparent;
                    width: 3px;
                }
            }
        }
    }
}


/**
 * Food
 */
