.form {
    display: block;
    position: relative;
    margin: 0 auto 30px;
    width: calc(100% - 0px);

    ion-list {
        background: var(--transparent) !important;
        margin: 0;
        padding: 0;
        box-shadow: 0 -12px 10px 10px rgba(0, 0, 0, 0.2);
    }

    ion-item {
        padding: 15px 12px;
        min-height: unset !important;
        box-shadow: none !important;
        // --background: var(--transparent);
        // background: var(--white);
        align-items: flex-start;
        --inner-padding-end: 0px;
        --inner-min-height: unset !important;
        --padding-start: 0;
        // --highligh-color-focused: var(--transparent) !important;
        box-shadow: none !important;
        --min-height: unset;
        overflow: visible;
        padding: 0 5px 0 0;

        border-top: 0 !important;
        border-bottom: 0;

        .icon-box {
            margin: 0 !important;
            margin-right: 13px !important;
            position: relative;
            top: 2px;

            img {
                width: 20px;
            }
        }

        ion-label {
            // color: var(--primary) !important;
            // font-family: var(--default-font);
            font-size: 1.1rem !important;
        }

        ion-textarea,
        ion-input {
            // --padding: 0 !important;
            // font-size: 1rem !important;
            // font-weight: 500 !important;
            // letter-spacing: 0.5px;
            // --padding-start: 0 !important;
            // --padding-end: 0 !important;
            // --padding-top: 0 !important;
            // --padding-bottom: 0px !important;
            // color: var(--text-black);
            // --placeholder-color: var(--text-light) !important;
            // --placeholder-opacity: 1;
            // --placeholder-font-weight: 500 !important;
            // flex: unset !important;
            // min-width: 100%;
            // height: 100%;
            // letter-spacing: 0.5px;

            // input {
            //     --padding: var(--no-padding);

            //     &.native-input {
            //         --padding: var(--no-padding);
            //     }
            // }
        }

        .form-control {
            width: 100%;
            margin: 0 5px 7px;
            border-radius: 15px;

            @include boxShadow(0.2);
            .native-input {
                padding: 6px 10px 4px;
            }

            &[disabled] {
                background: #adb0b5;
            }
        }

        // &.item-select {
        //     ion-select {
        //         width: 100%;
        //         max-width: unset;
        //         letter-spacing: 0.5px;
        //         padding: 0;
        //         font-size: 1.2rem !important;
        //         font-weight: 400 !important;
        //         --placeholder-color: var(--text-light) !important;
        //         --placeholder-opacity: 1;
        //         --placeholder-font-weight: 400 !important;
        //     }
        // }
    }

    ion-row {
        margin: 0 -5px;
    }

    &.d-flex {
        .form-group {
            width: 100%;
            padding: 0 5px;
            &:first-of-type {
                margin-left: -10px;
            }

            &:last-of-type {
                margin-right: -10px;
            }
        }
    }

    .form-group {
        label {
            margin-bottom: 2px;
            .required {
                color: #e84855;
            }
        }

        .input-group {
            display: flex;
            .form-control {
                border-top-right-radius: 0 !important;
                border-bottom-right-radius: 0 !important;
                border-right: 0 !important;
            }
            .input-icon-addon {
                padding: 10px 15px 0;
                &.right {
                    border: 1px solid #eff9ec;
                    border-left: 0 !important;
                    background: #fff;
                    border-top-right-radius: 20px;
                    border-bottom-right-radius: 20px;
                    margin-left: -1px;
                }
                img {
                    width: 22px;
                }
            }

            &.disabled {
                .form-control {
                    background: #ADB0B5 !important;
                    color: #dbdbdb !important;
                }
                .input-icon-addon{
                    background: #ADB0B5;
                }
            }
        }
    }

    ion-select {
        background: #fff;
        border-radius: 40px;
        height: 42px;
        --placeholder-color: #b3b3b3 !important;
        --placeholder-opacity: 1;
        --placeholder-font-weight: 400 !important;
    }

    .form-control {
        background: var(--color-brand-extra-light);
        color: var(--color-brand);
        padding: 4px 10px;
        border: 1px solid #eff9ec;
        &.rounded {
            border-radius: 20px;
        }

        &[disabled], &.disabled {
            background: #adb0b5 !important;
            border-color: #adb0b5 !important;
            color: #dbdbdb !important;
        }
        &::placeholder {
            color: #adb0b5 !important;
        }

        &[readonly] {
            pointer-events: none;
        }
    }

    textarea.form-control {
        min-height: 86px;
        border-radius: 20px !important;
    }
}

.form-action {
    margin-top: 20px;
    display: flex;
    justify-content: center;
}

/**
 * BUTTON
 */
button {
    outline: none;
}

.btn {
    border-radius: 40px;
    --border-radius: 40px;
    // padding: 8px 16px;
    min-width: 120px;
    font-size: 30px;
    font-weight: bold;
    // display: flex;
    // justify-content: center;
    // align-items: center;

    // ion-icon {
    //     zoom: 0.8;
    //     color: #fff;
    //     margin-left: 10px;
    //     margin-right: 10px;
    // }

    &.btn-default {
        color: var(--color-brand);
        font-family: var(--font-default) !important;
        font-weight: bold;
        white-space: nowrap;
        height: 42px;

        display: flex;
        justify-content: center;
        align-items: center;

        ion-icon {
            zoom: 0.8;
            color: var(--color-brand);
            margin-left: 10px;
            margin-right: 10px;
        }

        img {
            &.left {
                margin-right: 10px;
            }
            &.right {
                margin-left: 10px;
            }
        }

        &.bordered {
            border: 1px solid var(--color-brand);
        }

        &.btn-sm {
            font-size: 20px;
            height: 42px;

            img {
                height: 16px;
            }
        }
    }

    &.btn-primary {
        background-color: var(--color-brand);
        padding: 0;
        width: 160px;
        height: 62px;
        z-index: 1;
        position: relative;
        white-space: nowrap;

        &:hover {
            opacity: 0.9;
        }

        img {
            // width: 12px;
            height: 20px;
            &.left {
                margin-right: 10px;
            }
            &.right {
                margin-left: 10px;
            }
        }

        ion-icon {
            zoom: 0.8;
            color: #fff;
            margin-left: 10px;
            margin-right: 10px;
        }

        &::after {
            background: #fff;
            border: 1px solid #000;
            height: calc(100% - 8px);
            width: calc(100% - 6px);
            border-radius: inherit;
            position: absolute;
            right: 0;
            bottom: 0;
            content: "";
            z-index: -1;
        }

        .inner {
            display: flex;
            justify-content: center;
            align-items: center;
            background: var(--color-brand);
            border: 1px solid #000;
            // content: attr(data-caption);
            height: calc(100% - 4px);
            width: calc(100% - 3px);
            top: 0;
            left: 1px;
            margin-top: -3px;
            border-radius: 40px;
            color: #fff;
            font-size: 30px;
            font-weight: 500;
            img {
                margin-left: 10px;
            }
            // position: relative;
        }

        &.btn-sm {
            color: #fff;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 34px;
            width: 120px;
            font-size: 16px;
            font-weight: 400;

            &::after {
                display: none;
            }
        }

        &.disabled,
        &[disabled] {
            // opacity: 0.5;
            background: var(--color-gray-extra-light);

            &::after {
                border-color: var(--color-brand-dark);
            }

            .inner {
                background: var(--color-gray-extra-light);
                border-color: var(--color-brand-dark);
            }
        }
    }

    &.btn-secondary {
        background-color: #fff;
        border: 1px solid var(--color-brand-dark);
        height: 55px;
        padding: 10px 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: var(--color-brand);
        font-weight: 500;
        position: relative;
        &::after {
            background: var(--color-brand-dark);
            height: calc(100% + 5px);
            width: calc(100%);
            border-radius: inherit;
            position: absolute;
            left: 8px;
            bottom: -7px;
            content: "";
            z-index: -1;
        }
    }

    &.btn-sm {
        height: 34px;
        font-size: 0.8em;
    }

    &.btn-link {
        color: var(--color-brand);
        // text-decoration: underline;
        border-bottom: 1px solid var(--color-brand);
        line-height: 22px;
        // font-weight: 500;
        padding: 0;
        border-radius: 0;
        font-size: 20px;
        min-width: unset;
    }

    img.left {
        margin-right: 10px;
    }
}

ion-button {
    &.btn-default {
        --color: var(--color-brand);
    }
    &.btn-primary {
        --color: #fff;
    }
}

/** 
 * Checkbox
*/

.checkbox {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    span {
        font-size: 24px;
        display: inline-block;
        width: 90%;
        // width: 100%;
    }
    label {
        margin-bottom: 0 !important;
    }
}

.custom-picker {
    --background: #fff;
    --border-color: transparent;

    * {
        font-size: 30px;
        font-family: var(--default-font);
        font-weight: 500 !important;
    }

    .picker-toolbar {
        font-size: 20px !important;
        .picker-toolbar-button {
            button {
                color: var(--color-brand) !important;
                padding: 20px 40px;
            }

            &:first-of-type {
                button {
                    color: #e84855 !important;
                }
            }
        }
    }

    .picker-button {
        font-size: 24px !important;
    }

    .picker-columns {
        .picker-col {
            padding: 0;

            .picker-opts {
                .picker-opt {
                    word-spacing: 10px;
                }
            }

            .picker-opt-selected {
                // background-color: var(--color-gray-extra-light);
            }

            &:first-of-type {
                margin-left: 20px;
                // .picker-opt-selected {
                //     border-top-left-radius: 15px;
                //     border-bottom-left-radius: 15px;
                // }
            }
            &:last-of-type {
                margin-right: 20px;
                // .picker-opt-selected {
                //     border-top-right-radius: 15px;
                //     border-bottom-right-radius: 15px;
                // }
            }
        }

        .picker-above-highlight {
            background: linear-gradient(0deg, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 1) 100%) !important;
        }
        .picker-below-highlight {
            background: linear-gradient(0deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0.5) 100%) !important;
        }
    }
}

.picker-wrapper {
    border-top-left-radius: 40px !important;
    border-top-right-radius: 40px !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type="number"] {
    -moz-appearance: textfield;
}

.spinner-ios line,
.spinner-ios-small line {
    color: #fff;
}

ion-spinner {
    color: var(--color-brand);
    --color: var(--color-brand);
    &.white {
        color: #fff;
        --color: #fff;
    }
}

.popover {
    // display: flex;
    // justify-content: flex-end;

    * {
        --background: #fff;
        background: #fff;
    }
    
    ion-list {
        max-height: 250px;
        overflow-y: auto;
    }
    ion-item {
        font-size: 20px;
        // --background: #fff;
        img {
            margin-right: 10px;
        }
        span {
            background: transparent;
        }
        &:hover {
            --background: var(--color-brand);
            color: #fff;

            span {
                background: transparent;
                color: #fff;
            }
        }
    }
}
