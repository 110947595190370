/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";


/* custom */
@import "./theme/lyn2/style";
// @import "../src/assets/fonts/fonts";

:root {
	--default-font: "DBHeavent";

	--color-brand: #5BC13E;
	--color-brand-light: #BBE8BA;
	--color-brand-extra-light: #E8F7E9;
	--color-brand-dark: #004E42; 
	--color-gray: #667080;
	--color-gray-light: #ADB0B5;
	--color-gray-extra-light: #DBDBDB;
	
	--color-background-gray: #E5E5E5;
}